<template>
    <section>
        <modal ref="modalEliminarProyecto" titulo="Eliminar Proyecto" icon="trash" @guardar="eliminar">
            <ValidationObserver ref="validacion">
                <div class="row mx-0">
                    <div class="col-12">
                        <p class="text-muted2 f-15 text-center">
                            ¿Desea eliminar este proyecto? 
                        </p>
                        <p class="text-muted2 f-15 text-center">
                            Todas la tiendas que están asociadas a este proyecto desaparecerán
                        </p>
                    </div>
                    <div class="col-12 mt-4 mb-2">
                        <p class="text-muted2 f-15 text-center">
                            Confirmar escribiendo la palabra ELIMINAR
                        </p>
                    </div>
                    <div class="col-12 d-middle-center">
                        <div class="" style="width:239px;">
                            <ValidationProvider v-slot="{errors}" rules="required|max:40|rule_confirm" name="confirmar">
                                <el-input v-model="textEliminar" class="w-100" maxlength="10" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </ValidationObserver>

        </modal>
    </section>
</template>
<script>
import Proyectos from '~/services/proyectos/proyectos'
import { extend } from 'vee-validate';

extend('rule_confirm', {
  validate(value) {
        return value === 'ELIMINAR';
  },
  message: 'El campo {_field_} no corresponde.'
});

export default {
    data(){
        return{
            textEliminar: '',
            id_eliminar: null
        }
    },
    methods: {
        toggle(id){
             this.textEliminar= ''
            this.id_eliminar= null
            this.id_eliminar = id
            this.$refs.modalEliminarProyecto.toggle()
        },
        async eliminar(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const {data} = await Proyectos.eliminarProyecto({id: this.id_eliminar})
                this.notificacion('Mensaje', 'Proyecto eliminado', 'success')
                this.$store.commit('proyectos/proyectos/set_id_proyecto', null)
                let detalleInical ={
                    id: null,
                    nombre: null,
                    tipo: 1,
                    estado: 1,
                    descripcion: null,
                    base_datos: null,
                    dominio: null,
                    bucket_s3: null,
                      cant_tiendas: null,
                    tiendas_activas: null                            
                }
                this.$store.commit('proyectos/proyectos/set_detalle', detalleInical)
                this.$refs.modalEliminarProyecto.toggle()
                this.$emit('actualizar')

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>